@import 'material-themes';
@import 'foundation/foundation';
@import 'typography';
@import 'material/gf-material';

/*
  Our own bits of scss that don't make sense in encapsulated components, but still benefit from being organized
 */
@import 'components/approvals';
@import 'components/drag-drop';
@import 'components/signin';

@include foundation-everything(
  $flex: true,
  $prototype: true,
  $xy-grid: true);

:root {
  // This has to be set here, provides fallback for this --app-vh variable
  --app-vh: 100%;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  @include font-style('Inter', 16px, 400, 22px);
}

input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
textarea:-internal-autofill-selected,
textarea:-internal-autofill-selected:hover,
textarea:-internal-autofill-selected:focus,
select:-internal-autofill-selected,
select:-internal-autofill-selected:hover,
select:-internal-autofill-selected:focus {
  background-color: white !important;
}


// TODO: Find better places for all this random shit

.scrolling-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.empty-container {
  align-items: center;
  background: map-get($gf-grey, 50);
  border: 1px solid map-get($gf-grey, 200);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
}

/* Vertical Divider line styles here */
.vertical-divider {
  width: 1px;
  height: 100%;
  background-color: map-get($gf-black, 300);
}

/* image centered style here */
.img-centered {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

/* image circle styles here */
.img-circle {
  border-radius: 50%;
}

/* Outline styles here */
.outline-none {
  outline: none;
}

/* overflow styles here */
.overflow-auto {
  overflow: auto;
}

/* External Margin styles here */

/* Margin top styles here */
.mt {
  &-40 {
    margin-top: 2.5rem;
  }
  &-64 {
    margin-top: 4rem;
  }
  &-4 {
    margin-top: 0.25rem !important;
  }
  &-6 {
    margin-top: 0.375rem !important;
  }
  &-12 {
    margin-top: 12px !important;
  }
}

/* Margin bottom styles here */
.mb {
  &-4 {
    margin-bottom: 0.25rem !important;
  }
  &-10 {
    margin-bottom: 0.625rem !important;
  }
  &-12 {
    margin-bottom: 0.75rem !important;
  }
  &-40 {
    margin-bottom: 2.5rem !important;
  }
}

/* Margin left styles here */
.ml {
  &-4 {
    margin-left: 0.25rem !important;
  }
  &-6 {
    margin-left: 0.375rem !important;
  }
  &-10 {
    margin-left: 0.625rem !important;
  }
  &-12 {
    margin-left: 0.75rem !important;
  }
  &-40 {
    margin-left: 2.5rem !important;
  }
  &-auto {
    margin-left: auto !important;
  }
}

/* Margin right styles here */
.mr {
  &-4 {
    margin-right: 0.25rem !important;
  }
  &-12 {
    margin-right: 0.75rem !important;
  }
}

/* Margin left and right styles here */
.mx {
  &-12 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
}

/* Margin top and bottom styles here */
.my {
  &-6 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
}

/* External Padding styles here */
.pa-4 {
  padding: 0.25rem;
}

/* Padding left styles here */

.pl {
  &-4 {
    padding-left: 0.25rem;
  }
  &-12 {
    padding-left: 0.625rem;
  }
}

/* Padding top & bottom and left & right styles here */
.py {
  &-4 {
    padding: 0.25rem 0;
  }
  &-12 {
    padding: 0.75rem 0;
  }
}

/* Padding bottom styles here */
.pb {
  &-4 {
    padding-bottom: 0.25rem;
  }
  &-56 {
    padding-bottom: 56px !important;
  }
}


@include breakpoint(small down) {
  // Margin
  .m-m-r-0 {
    margin-right: 0 !important;
  }

  // Padding
  .m-p-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  // Flex - for small down
  .s-flex-dir-column {
    flex-direction: column;
  }

  // Width
  .m-width-100 {
    width: 100% !important;
  }
}

/* Image background theme styles here */
.trans-bg {
  background: url('/assets/images/bg-transparent.svg');
}

/* Text Wrap Style here */
.break-all {
  white-space: normal;
  word-break: break-all;
}

/* Flex external styles added here */
.flex {
  &-1 {
    flex: 1;
  }
  &-4 {
    flex: 4
  }
  &-5 {
    flex: 5;
  }
  &-6 {
    flex: 6;
  }
}

/** Icon hover styles here **/
.icon-hover:hover {
  background-color: map-get($gf-grey, 50) !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

/* Text align styles here */
.text {
  &-center {
    text-align: center;
  }
  &-end {
    text-align: end;
  }
}

/* Opacity styles here */
.opacity08 {
  opacity: 0.8;
}

.visibility-hidden {
  visibility: hidden;
}

/* Border Styles here */
.border-bottom-grey {
  border-bottom: 1px solid $gf-divider;
}

.welcome-modal-backdrop {
  // padding: 0 40px 0 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 100%, rgba(255, 255, 255, 0.7) 0%) !important;
  background-color: transparent !important;
  backdrop-filter: blur(0px) !important;
}

/* Common Color classes here */
.primary-color {
  color: map-get($gf-orange, 200);
}

.error-color {
  color: map-get($gf-red, 500);
}

.text-decoration-none {
  text-decoration: none;
}

.downloaded-info {
  color: map-get($gf-grey, 400);
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  align-items: center;

  p {
    padding-right: 2px;
    line-height: 1.5;
  }
}

.add-on-icon-wrap {
  background-image: linear-gradient(to top, #ff8600, #ffc100);
  border-radius: 16px;
  flex-grow: 0;
  height: 48px;
  padding: 10px;
  width: 48px;
}

.graphics-builder-icon {
  background-color: map-get($gf-grey, 500);
  border-radius: 6px;
  flex-grow: 0;
  height: 20px;
  width: 20px;
  padding: 2px;

  &.medium {
    height: 24px;
    width: 24px;
    margin: 4px 8px 4px 0;
    padding: 3px;
  }
}

// For Displaying Multiline tooltips
.multi-line-tooltip  {
  white-space: pre-line !important;
}

.tooltip-fit {
  max-width: 280px !important;
}

.delete-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cursor-none {
  pointer-events: none !important;
  display: inline-block;
}

.pointer-events-none {
  pointer-events: none !important;
}

.w-100 {
  width: 100% !important;
}

.collaborator-avatar {
  .avatar {
    box-shadow: 0 0 0 3px map-get($gf-grey, 50) !important;
  }
}

.user-unassigned-img {
  padding: 6px;
  border-radius: 100px;
  background-color: map-get($gf-grey, 100);
  color: map-get($gf-grey, 300);

  &.transparent {
    background-color: transparent;
  }
}

.icon {
  align-items: center;
  border-radius: 2px;
  color: $light-primary-text;
  display: flex;
  flex: 0 0 3rem;
  font-size: 1rem;
  font-weight: bold;
  height: 3rem;
  justify-content: center;
  width: 3rem;
}

.notes {
  background-color: map-get($gf-grey, 100) !important;
  padding: 4px 8px !important;
  max-height: 300px;
  overflow-y: auto;

  &.internal {
    min-height: 100px !important;
    padding-left: 1rem !important;
  }

  &.customer {
    min-height: 76px !important;
  }

  &.stock-art {
    min-height: 130px !important;
  }
}

.results-loading {
  padding: 10px 24px;
  border-radius: 24px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid $oc-white;
  background-image: linear-gradient(to bottom, $oc-white, $oc-white), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12));
  z-index: 3;

  .loader {
    width: 28px;
    height: 28px;
    position: relative;
  }
}

.transform-90 {
  transform: rotate(90deg);
}

.color-indicator {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 52px;
  margin-right: 0.25rem;
}

.selected-option-color {
  background-color: map-get($gf-grey, 50) !important;
}

// CSA Mobile Scenarios
@include breakpoint(small down) {
  .floating-action-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1rem 1.5rem !important;
    z-index: 1000;
    box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.1);

    &.csa-actions-container {
      background-color: $oc-white;
    }

  }

  .scroll-reset-btn {
    background-color: $oc-blue-10 !important;
    color: $oc-white !important;
    position: fixed !important;
    bottom: 90px;
    z-index: 1000;
    box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.1) !important;
    opacity: 0;

    &.show {
      left: 24px;
      animation: fadeIn 100ms linear;
      opacity: 1;
    }

    &:not(.show) {
      left: -100px;
      animation: fadeOut 100ms linear;
      opacity: 0;
    }
  }

  // Basic Keyframes for Fade In
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Basic Keyframes for Fade Out
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

/* CSS specific for mobile view iOS devices - CSA detail page hiding in the footer section. So i giving the bottom margin.
We need to refactor it later! */
.detail-view-bottom-margin {
  @include breakpoint(small down) {
    margin-bottom: 96px;
  }
}

// CSA beta tag - Holding this style for further needs
.beta-flag {
  border: 1px solid $oc-blue-10;
  border-radius: 20px;
  padding: 1px 8px;
  color: $oc-blue-10 !important;

  &.white {
    border: 1px solid $oc-white;
    color: $oc-white !important;
  }
}
textarea {
  box-sizing: inherit !important;
}

.scroll-shadow {
  box-shadow: inset 0 12px 8px -8px rgba(0, 0, 0, 0.12);
  display: none;
  height: 12px;
  position: absolute;
  width: 100%;
  z-index: 2;

  &.show {
    display: block;
  }
}

virtual-scroller {
  height: 100%;
}

.no-folders {
  background-image: url('/assets/images/bg-empty-folder.svg');
  background-repeat: no-repeat;
  background-position: center;

  @include breakpoint(small down) {
    text-align: center;
  }
}

.dark-preview-bg {
  background-color: rgba(0, 0, 0, 0.75);
}

.hint-light {
  color: map-get($gf-grey, 200);
}

.no-result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include breakpoint(small down) {
    display: block;
    padding-bottom: 2.5rem;
    height: auto;
  }
}

.content-box {
  box-sizing: content-box !important;
}

// Team members role chip styles.
.team-status {
  border-radius: 6px;
  color: $oc-white;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 0.5rem;

  &.member {
    background-color: map-get($gf-grey, 300);
  }

  &.admin {
    background-color: map-get($gf-green, 300);
  }

  &.owner {
    background-color: map-get($gf-orange, 200);
  }

  &.support {
    color: map-get($gf-grey, 400);
    background-color: map-get($gf-grey, 75);
  }
}

.align-with-approval-items {
  // footer in the approval detail have to be left aligned wrt approval item
  .footer {
    margin-top: 20px !important;
  }

  .footer, .timeline-wrapper {
    padding-left: 40px;
  }

  @include breakpoint(large up) {
    .approval-timeline-container {
      width: 920px !important;
    }
  }

  @include breakpoint(medium down) {
    .footer, .timeline-wrapper {
      padding-left: unset;
    }
  }
}

// timeline events styles
.first-event {
  .event-icon mat-divider {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.last-event {
  .event-icon mat-divider {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

// Re-captcha error
.captcha-error > div:first-child {
  border: 2px solid map-get($gf-red, 500);
  border-radius: 5px;
  width: 306px !important;
  height: 80px !important;
}

// Category filter
.category-dropdown, .category-filter-mobile {
  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

// Create design request date picker
.dr-date-picker {
  .mat-calendar-body-selected {
    background-color: map-get($gf-grey, 200) !important;
  }

  .mat-calendar-body-cell {

    &:hover .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: $gf-divider !important;
    }
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    background-color: $gf-divider !important;
  }
}

// Design request custom date picker field
.customFieldForm {
  .dr-date-picker .mat-calendar-body-selected {
    color: $oc-white !important;
  }
}
