@import 'imports';

body.default {

  .mat-mdc-progress-bar {

    &.progress-bar-large {
      --mdc-linear-progress-track-color: #{map-get($gf-grey, 100)};
      --mdc-linear-progress-active-indicator-color: #{map-get($gf-green, 100)};
      --mdc-linear-progress-active-indicator-height: 8px;
      --mdc-linear-progress-track-height: 8px;
      --mdc-linear-progress-track-shape: 4px;
      border-radius: 4px;

      &.mat-warn {
        --mdc-linear-progress-active-indicator-color: #{map-get($gf-red, 500)};
      }
    }

    &.csa-progress-bar {
      --mdc-linear-progress-active-indicator-color: #{$oc-blue-10};
    }
  }
}
