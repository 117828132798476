mat-form-field.mat-mdc-form-field {

  .mat-mdc-input-element {
    box-sizing: inherit !important;
  }

  &.filter-form-field {

    .mat-mdc-text-field-wrapper {
      padding: 0 .5rem 0 1rem;
      background-color: map-get($gf-grey, 100);
      border-radius: 4px;

      .mat-mdc-form-field-flex {
        .mat-form-field-label {
          @include t(14px, 500, p);

          .mat-mdc-form-field-required-marker {
            display: none;
          }
        }

        .mat-mdc-form-field-infix {
          border-top: 0;
          width: 100px;
          padding: .5rem 0;
          min-height: 40px;

          input {
            font-weight: 500;

            &::placeholder {
              color: map-get($gf-grey, 300);
              font-size: 14px;
              -webkit-font-smoothing: auto;
            }
          }

          .mat-mdc-select-placeholder,
          .multi-select-label-trigger {
            @include t(14px, 500, p);
            -webkit-font-smoothing: auto;
          }
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-icon-prefix {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: .5rem;

          mat-icon {
            padding: 0;
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mdc-line-ripple,
    .mat-mdc-select-arrow-wrapper,
    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    &.mat-focused {
      .mat-mdc-form-field-focus-overlay {
        background-color: unset;
      }
    }

    &.category-search-input {
      --mat-form-field-container-text-line-height: 24px;

      .mat-form-field-label {
        color: map-get($gf-grey, 300);
        font-size: 16px;
        font-weight: normal;

        .mat-mdc-form-field-required-marker {
          display: none;
        }
      }

      .mat-mdc-text-field-wrapper {
        padding: 6px 14px;

        &.mat-mdc-form-field-infix {
          min-height: 38px;
        }
      }

      @include breakpoint(small down) {

        .mat-mdc-text-field-wrapper {
          padding: 6px 0.5rem 6px 1rem;
          background-color: map-get($gf-black, 100)
        }
      }
    }

    .mdc-text-field--filled {

      &.mdc-text-field--focused,
      &:hover {
        border-bottom-right-radius: var(--mdc-filled-text-field-container-shape) !important;
        border-bottom-left-radius: var(--mdc-filled-text-field-container-shape) !important;
      }
    }
  }

  &.float-form-field {

    .mat-mdc-text-field-wrapper {
      padding: 0;
      margin: 0;
      background-color: map-get($gf-grey, 100);

      .mat-mdc-form-field-flex {
        padding: 0 1rem;

        .mdc-floating-label {
          @include t(16px, 400, p);
          color: map-get($gf-grey, 300);
          -webkit-font-smoothing: auto;
          top: 24px;
        }

        .mat-mdc-form-field-required-marker::after {
          display: none;
        }

        .mat-mdc-form-field-infix {
          min-height: 48px;
          padding-top: 20px;
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-icon-preffix {
          height: 1.5rem;
          width: 1.5rem;

          mat-icon {
            padding: 0;
          }
        }

      }
    }

    .mat-mdc-form-field-subscript-wrapper {

      .mat-mdc-form-field-error-wrapper .mat-mdc-form-field-error {
        margin-top: 2px !important;
        font-size: 12px;
        font-weight: 500;
        -webkit-font-smoothing: auto;
      }
    }

    &.mat-focused {
      .mat-mdc-form-field-focus-overlay {
        display: none;
      }

      .mdc-text-field--filled:not(.mdc-text-field--disabled),
      .mdc-text-field--focused:not(.mdc-text-field--disabled) {

        &.mdc-text-field--invalid {

          .mdc-line-ripple::after,
          .mdc-line-ripple::before {
            border-bottom-color: map-get($gf-red, 500) !important;
          }
        }

        .mdc-line-ripple::after,
        .mdc-line-ripple::before {
          border-bottom-color: map-get($gf-black, 600);
        }
      }
    }

    &.extra-spacing-bottom {
      .mat-mdc-form-field-subscript-wrapper {
        margin-bottom: .25rem;
      }
    }
  }

  &.select-form-feild {
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0;
      padding: 0 8px;
      height: 2.5rem;
      background-color: map-get($gf-grey, 100);
      border-radius: 4px;

      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        padding: 0;
        min-height: 2.5rem;
        display: flex;
        align-items: center;

        .mat-mdc-select {
          height: 34px;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mdc-line-ripple,
    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    &.assignee-select {
      .mat-mdc-text-field-wrapper {
        height: 3rem;
        background-color: map-get($gf-grey, 50);

        .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
          min-height: 3rem;
        }
      }
    }

    &.mat-focused {
      .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  &.bold-select-value {

    .mat-mdc-select .mat-mdc-select-value {
      font-weight: 500;
      margin-left: 4px;
      -webkit-font-smoothing: auto;
    }
  }

  &.notification-form-select {

    .mat-mdc-select {

      &.error {
        .mat-mdc-select-value {
          .mat-mdc-select-placeholder {
            color: map-get($gf-red, 500) !important;
          }
        }
      }
    }
  }
}

// Create design request date picker
.date-picker {
  .mat-mdc-form-field-infix {
   display: flex;
   padding: .5625rem 0;
 }
}

// Header Search box
.gf-header-search {
  .mat-mdc-form-field.mat-focused {
    border: 1px solid #93d3fa;
  }
}
