@import 'imports';

body.default {
  .mat-drawer-backdrop.mat-drawer-shown {
    background: rgba(0, 0, 0, 0.16);
  }
}

// Backdrop styles when side nav opens
.drawer-container {
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(33, 40, 46, 0.85) !important;
  }
}

.mobile-drawer {
  @include breakpoint(small down) {
    mat-drawer {
      width: 100vw !important;
    }
  }
}

// Side Nav styles
.sidenav-container {
  .mat-drawer-inner-container {
    overflow: hidden !important;
    background-color: $gf-primary-text;
  }
}

.details-view-drawer {
  .mat-drawer-inner-container {
    background-color: $gf-primary-text;
  }
}
