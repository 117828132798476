@import 'imports';

.droppable .drop-indicator {
  opacity: 1;
}

.drop-indicator {
  border: solid 3px $oc-blue-10;
  background-color: rgba(0, 122, 255, 0.12);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
