.mat-mdc-autocomplete-panel {
  border-radius: 8px !important;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.16) !important;

  .mat-mdc-option {
    line-height: 40px;
    height: 40px;
    padding: 0 16px;
    font-size: 16px;
    min-height: unset;

    &:hover {
      color: $oc-white !important;

      .mdc-list-item__primary-text {
        color: $oc-white;
      }
    }
  }
}
