@import 'imports';

.login-form-container {
  min-width: 480px;
  max-width: 480px;
  padding: 2.5rem 3rem;
  border-radius: 8px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  background: $oc-white;
  color: map-get($gf-black, 900);

  @include breakpoint(small down) {
    min-width: unset;
    max-width: unset;
  }
}
