button:disabled:hover {
  cursor: not-allowed;
}

button.rounded-button {
  border-radius: 18px;
}

body.default {
  button,a {
    &.mat-mdc-button,
    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button,
    &.mat-mdc-outlined-button {

      -webkit-font-smoothing: auto;

      &.mat-mdc-button-base {
        height: 40px;
        line-height: 40px;
        border-radius: 24px;

        .mat-mdc-button-touch-target {
          height: inherit;
        }

        .mdc-button__label {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.small {
        height: 32px;
        line-height: 32px;
      }

      &.medium {
        height: 40px;
        line-height: 40px;
      }

      &.large {
        height: 48px;
        line-height: 48px;
        border-radius: 36px;
      }

      &.x-large {
        height: 56px;
        line-height: 56px;
        border-radius: 36px;
      }

      &.suppress-hover-style {
        .mat-mdc-focus-indicator,
        .mdc-button__ripple, .mat-mdc-button-persistent-ripple {
          opacity: 0 !important;
        }

        &:hover {
          background-color: transparent !important;
        }
      }

      &[disabled] {
        cursor: not-allowed;
        pointer-events: unset;
      }
    }
  }

  button.mat-mdc-button,
  a.mat-mdc-button {
    @include t(14px, 500, s);
    color: $dark-primary-text;
    padding: 0 1.50rem;
    transition: all 0.3s;

    &.mat-accent {
      color: $oc-blue-13;
    }

    &.mat-primary {
      color: map-get($gf-orange, 300);
    }

    &.help-btn {
      padding: .5rem 1rem .5rem .5rem;
      border-radius: 30px;

      @include breakpoint(small down) {
        padding: .5rem;

        .mat-icon {
          height: 1.5rem;
          width: 1.5rem;
          font-size: 1.5rem;
        }
      }
    }

    &.full-width {
      width: 100%;
    }

    &.rounded {
      border-radius: 24px;
    }

    &.clear-all-btn {
      color: $oc-blue-13;

      &.mat-mdc-button-base {
        border-radius: .5rem;
        height: fit-content;
        line-height: unset;
        padding: .25rem 0;
      }
    }

    &:not([disabled]):not(.help-btn):hover {
      background-color: map-get($gf-grey, 50);

      .mat-mdc-focus-indicator,
      .mdc-button__ripple {
        opacity: 0 !important;
      }
    }
  }

  button.mat-mdc-outlined-button,
  a.mat-mdc-outlined-button {
    border-radius: 24px;
    border: solid 1px map-get($gf-black, 300);
    box-shadow: 0 1px 1px 0 map-get($gf-black, 400);
    color: $gf-primary-text !important;
    height: 48px;
    padding: 0 1.50rem;
    transition: all 0.3s;

    // Button colors
    &.mat-primary {
      color: $oc-white !important;
      background-color: map-get($gf-orange, 300) !important;
      border-color: map-get($gf-orange, 300) !important;

      &:hover {
        background-color: map-get($gf-orange, 500) !important;
        border-color: map-get($gf-orange, 500) !important;
      }

      &[disabled] {
        background-color: map-get($gf-grey, 100) !important;
        border-color: map-get($gf-grey, 100) !important;
      }
    }

    &.flat-button {
      border: 1px solid map-get($gf-grey, 300);
      border-radius: 4px;
      padding: 0 1rem;
      box-shadow: none;

      .mdc-button__label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &.active-border {
        &.mat-mdc-button-base {
          border: 2px solid map-get($gf-orange, 200);
        }

        mat-icon {
          color: map-get($gf-orange, 200);
        }
      }

      &.error-border {
        &.mat-mdc-button-base {
          border: 2px solid map-get($gf-red, 500);
        }
      }
    }

    &:hover {
      background-color: map-get($gf-grey, 100);

      .mat-mdc-focus-indicator,
      .mdc-button__ripple {
        opacity: 0 !important;
      }
    }

    &[disabled] {
      background-color: map-get($gf-grey, 100) !important;
      border-color: map-get($gf-grey, 100) !important;
      color: map-get($gf-grey, 300) !important;
    }
  }

  button.mat-mdc-raised-button,
  a.mat-mdc-raised-button {
    border-radius: 24px;
    border: 1px solid map-get($gf-black, 400);
    box-shadow: 0 1px 0 map-get($gf-black, 250);
    color: $oc-white;
    height: 3rem;
    padding: 0 1.50rem;
    transition: all 0.3s;

    &.full-width {
      width: 100%;
    }

    &.elevate {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    &.default-border {
      box-shadow: 0 1px 0 map-get($gf-black, 250) !important;

      &[disabled] {
        border: none !important;
        color: map-get($gf-grey, 300) !important;
        background-color: map-get($gf-grey, 100) !important;

        &:active {
          outline: none;
        }
      }

      &:active {
        outline: 2px solid $oc-blue-10;
        outline-offset: 2px;
        box-shadow: 0 1px 0 map-get($gf-black, 250) !important;
      }
    }

    &.notification-btn {
      border: none !important;
      height: 40px;
      background-color: $gf-link-color !important;
      color: $oc-white !important;

      mat-icon {
        opacity: 0.8;
      }

      &:hover {
        background-color: $oc-blue-11 !important;
      }

      &[disabled] {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: rgba(0, 0, 0, 0.32) !important;

        &:hover {
          color: map-get($gf-grey, 300) !important;
        }
      }

      &.warn {
        background-color: map-get($gf-red , 500);

        &:hover {
          background-color: map-get($gf-red , 500);
        }
      }

      &.mat-48 {
        height: 48px;
      }
    }

    &.mat-primary {
      color: $oc-white;
      background-color: map-get($gf-orange, 200) !important;
      box-shadow: 0 1px 0 map-get($gf-black, 250) !important;
      border: 1px solid map-get($gf-black, 400) !important;

      &:hover {
        background-color: map-get($gf-orange, 800) !important;
      }

      &:active {
        outline: 2px solid $oc-blue-10;
        outline-offset: 2px;
      }

      &[disabled] {
        border: none !important;
        color: map-get($gf-grey, 300) !important;
        background-color: map-get($gf-grey, 100) !important;

        &:active {
          outline: none;
        }
      }
    }

    &.mat-secondary {
      color: map-get($gf-grey, 500) !important;
      background-color: $oc-white !important;
      box-shadow: 0 1px 0 map-get($gf-black, 250) !important;
      border: 1px solid map-get($gf-black, 400) !important;

      &:hover {
        background-color: map-get($gf-grey, 100) !important;
      }

      &[disabled] {
        border: none !important;
        color: map-get($gf-grey, 300) !important;
        background-color: map-get($gf-grey, 100) !important;

        &:active {
          outline: none;
        }
      }

      &:active:not(.action-bar):not(.thick-border):not(.success):not(.warn) {
        outline: 2px solid $oc-blue-10;
        outline-offset: 2px;
      }

      &.thick-border {
        border: 2px solid map-get($gf-grey, 300) !important;

        &[disabled] {
          opacity: 0.3;
        }
      }

      &.success {
        background-color: $gf-approved-green !important;
        color: $oc-white !important;
        border: none !important;

        &:hover {
          background-color: map-get($gf-green, 200) !important;
        }
      }

      &.warn {
        background-color: $gf-rejected-red !important;
        color: $oc-white !important;
        border: none !important;

        &:hover {
          background-color: map-get($gf-red, 400) !important;
        }
      }

      &.action-bar {
        box-shadow: 0 1px 1px map-get($gf-black, 250) !important;

        &:hover {
          background-color: map-get($gf-grey, 50) !important;
        }

        &:active {
          background-color: map-get($gf-grey, 100) !important;
        }

        &[disabled] {
          color: map-get($gf-grey, 500) !important;
          background-color: $oc-white !important;
          border: 1px solid map-get($gf-black, 400) !important;

          &.mat-mdc-button-base {
            opacity: .4;
          }
        }
      }
    }

    &.mat-warn {
      background-color: map-get($gf-red, 500);
      border: 1px solid map-get($gf-black, 400);
      box-shadow: 0px 1px 0px map-get($gf-black, 250) !important;

      &:hover {
        background-color: map-get($gf-red, 400) !important;
      }

      &:active {
        outline: 2px solid $oc-blue-10;
        outline-offset: 2px;
      }

      &[disabled] {
        border: none;
        color: map-get($gf-grey, 300) !important;
        background-color: map-get($gf-grey, 100) !important;

        &:active {
          outline: none;
        }
      }
    }

    &.arrow-button {
      min-width: 3rem !important;
      padding: 0;
    }

    &:hover:not(.mat-primary):not(.mat-secondary):not(.mat-warn):not(.default-border):not(.notification-btn) {
      border-color: map-get($gf-orange, 300);
      background-color: map-get($gf-orange, 300);
      color: $oc-white !important;
    }

    &[disabled] {

      &:hover {
        color: map-get($gf-grey, 300) !important;
      }
    }

    .mat-mdc-button-ripple, .mdc-button__ripple, .mat-mdc-focus-indicator {
      opacity: 0 !important;
    }
  }

  button.mat-mdc-unelevated-button,
  a.mat-mdc-unelevated-button {

    &.mat-mdc-button-base {
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.87);

      .mdc-button__label {
        justify-content: space-between;
      }
    }

    &.mat-secondary {
      background-color: map-get($gf-grey, 100);
    }
  }

  button.mat-mdc-icon-button,
  a.mat-mdc-icon-button {
    color: map-get($gf-grey, 400);

    &.mat-mdc-button-base {
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-mdc-button-touch-target {
        height: inherit;
        width: inherit;
      }
    }

    &:active:not([disabled]),
    &.active:not([disabled]) {
      background-color: map-get($gf-grey, 100);
    }

    &.mat-20 {
      line-height: 20px;
      height: 20px;
      width: 20px;
    }

    &.mat-24 {
      line-height: 20px;
      height: 24px;
      width: 24px;
    }

    &.mat-32 {
      line-height: 32px;
      height: 32px;
      width: 32px;
    }

    &.mat-40 {
      line-height: 40px;
      height: 40px;
      width: 40px;
    }

    &.mat-48 {
      line-height: 48px;
      height: 48px;
      width: 48px;

      &.stock-art-edit {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
        border: solid 1px rgba(0, 0, 0, 0.15) !important;
        background-color: $oc-white !important;

        &:hover {
          background-color: map-get($gf-grey, 100) !important;
        }
      }
    }

    &.mat-primary {
      color: $oc-white !important;
      background-color: map-get($gf-orange, 200) !important;
      box-shadow: 0 1px 0 map-get($gf-black, 250) !important;
      border: 1px solid map-get($gf-black, 400) !important;

      &:hover {
        background-color: map-get($gf-orange, 800) !important;
      }

      &[disabled] {
        border: none !important;
        color: map-get($gf-grey, 300) !important;
        background-color: map-get($gf-grey, 100) !important;
      }
    }

    &.undo-btn,
    &.redo-btn {
      min-width: 3rem;
      height: 3rem;
      padding: 0;

      &[disabled] {
        color: map-get($gf-grey, 200);
      }
    }

    &.remove-selection-btn {
      background-color: rgba(144, 150, 157, 0.1);

      mat-icon {
        font-size: 20px;
        line-height: 22px;
        color: $gf-hint-text;
      }

      &:hover {
        background-color: map-get($gf-red, 500) !important;

        mat-icon {
          color: $oc-white;
        }
      }
    }

    &.suppress-hover-style {
      .mat-mdc-focus-indicator,
      .mdc-button__ripple, .mat-mdc-button-persistent-ripple {
        opacity: 0 !important;
      }

      &:hover {
        background-color: transparent !important;
      }
    }

    &[disabled] {
      color: map-get($gf-grey, 100);
      cursor: not-allowed;
      pointer-events: unset;

      &.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
        opacity: 0;
      }
    }

    &:hover:not([disabled]):not(.suppress-hover-style) {
      background-color: map-get($gf-grey, 50);
    }

    .mat-mdc-button-ripple, .mdc-button__ripple, .mat-mdc-focus-indicator {
      opacity: 0;
    }

    &.favorite-btn {
      display: none;

      &.visible {
        display: flex;
      }

      @include breakpoint(large down) {
        display: flex;
      }
    }
  }

  button.mat-mdc-mini-fab {
    &.add-and-remove-btn {
      border: none;
      height: 40px;
      background-color: $gf-link-color !important;
      color: $oc-white !important;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;

      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      &:hover {
        background-color: $oc-blue-11 !important;
      }

      &.remove-btn {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
        border: solid 1px rgba(0, 0, 0, 0.15) !important;
        background-color: $oc-white !important;
        color: map-get($gf-grey, 300) !important;

        &:hover {
          background-color: map-get($gf-red, 500) !important;
          color: $oc-white !important;
        }
      }

      &[disabled] {
        border: none !important;
        background-color: map-get($gf-grey, 100) !important;
        color: map-get($gf-grey, 300) !important;

        &:hover {
          border: none !important;
          background-color: map-get($gf-grey, 100) !important;
          color: map-get($gf-grey, 300) !important;
        }
      }

      &.custom-32 {
        height: 2rem !important;
        width: 2rem !important;
        border: 1px solid map-get($gf-black, 400);
      }
    }

    &.base-button {
      &.mat-mdc-button-base {
        padding: 0;
      }

      border: 1px solid map-get($gf-black, 400) !important;
      box-shadow: 0 1px 0 map-get($gf-black, 250) !important;
      color: map-get($gf-grey, 400) !important;
      background-color: $oc-white !important;

      &:hover {
        background-color: map-get($gf-grey, 100) !important;
      }

      &[disabled] {
        background-color: map-get($gf-grey, 100) !important;
        border: 1px solid map-get($gf-black, 400) !important;
      }
    }

    &.carousel-slider-button {
      &.mat-mdc-button-base {
        padding: 0;
      }

      border: 1px solid map-get($gf-grey, 100) !important;
      box-shadow: none !important;
      color: map-get($gf-grey, 300) !important;
      background-color: $oc-white !important;

      &:hover {
        background-color: map-get($gf-grey, 100) !important;
        border: 1px solid map-get($gf-grey, 300) !important;
      }

      &[disabled] {
        background-color: $oc-white !important;
        border: 1px solid map-get($gf-grey, 50) !important;
        color: map-get($gf-grey, 100) !important;
      }
    }

    &.link-btn {
      color: map-get($gf-grey, 500) !important;
      background-color: $oc-white !important;
      box-shadow: 0 1px 0 map-get($gf-black, 250) !important;
      border: 1px solid map-get($gf-black, 400) !important;

      &:hover {
        background-color: map-get($gf-grey, 100) !important;
        color: map-get($gf-grey, 300) !important;
      }
    }

    &.mat-32 {
      line-height: 32px;
      height: 32px;
      width: 32px;
    }

    &.mat-48 {
      line-height: 48px;
      height: 48px;
      width: 48px;
    }

    &[disabled] {
      background-color: map-get($gf-grey, 100) !important;
      border-color: map-get($gf-grey, 300) !important;
    }

    &.btn-active {
      background-color: map-get($gf-grey, 100) !important;
    }

    .mat-mdc-button-touch-target {
      height: inherit;
      width: inherit;
    }

  }

  button.mat-mdc-fab {

    &.mat-48 {

      &.mat-mdc-button-base {
        padding: 0;
      }

      mat-icon {
        font-size: 32px;
        height: 48px;
        line-height: 48px;
        width: 48px;
      }
    }

    &.mat-24 {
      line-height: 24px;
      height: 24px;
      width: 24px;
    }

    &.mat-40 {
      height: 40px;
      width: 40px;

      &.mat-mdc-button-base {
        padding: 0;
      }

      mat-icon {
        font-size: 24px;
        height: 24px;
        line-height: 24px;
        width: 24px;
      }
    }
  }

  /* Mat Button Toggle Group styles here */
  .medium-button-toggle-group {
    border: solid 1px map-get($gf-grey, 300) !important;

    .mat-button-toggle {
      font-size: 14px;

      &+.mat-button-toggle {
        border-left: solid 1px map-get($gf-grey, 300) !important;
      }

      &.mat-button-toggle-checked {
        background-color: map-get($gf-grey, 100);
      }
    }

    .mat-button-toggle-disabled {
      &.cdk-focused {
        outline: none;
      }
    }

    .mat-button-toggle-label-content {
      line-height: 40px;
    }
  }

  .panel-action-buttons {

    button, a {
      &.mat-mdc-raised-button,
      &.mat-mdc-outlined-button {
        height: 2.5rem;
        @include t(13px, 500, p);
        padding: 0 1rem;
        border-radius: 0;
        box-shadow: 0 1px 1px 0 rgb(0, 0, 0, 15%) !important;

        @include breakpoint(small down) {
          padding: 0 8px 0 12px;
        }

        &:first-child {
          border-radius: 1.5rem 0 0 1.5rem !important;
        }

        &:last-child {
          border-radius: 0 1.5rem 1.5rem 0 !important;
          min-width: unset !important;
        }

        &.mat-white {
          color: map-get($gf-grey, 500) !important;
          background-color: $oc-white !important;
          border-color: rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;

          &:hover:not([disabled]) {
            background-color: map-get($gf-grey, 50) !important;
          }

          &:active:not([disabled]) {
            background-color: map-get($gf-grey, 100) !important;

            .mat-icon {
              color: map-get($gf-grey, 400) !important;
            }
          }

          &[disabled] {

            &.mat-mdc-button-base {
              opacity: 0.4 !important;
            }
          }
        }
      }

      .mat-mdc-focus-indicator {
        opacity: 0 !important;
      }
    }
  }
}

// Art portal add art item icon
.add-stock-art-wrap {
  .mat-mdc-fab.mat-accent {
    background-color: #007aff !important;
  }
}

// Design request success button
.success-check-wrap {
  button {
    background-color: map-get($gf-green, 300);
    height: 80px;
    width: 80px;

    mat-icon {
      font-size: 30px;
      margin-bottom: 4px;
      margin-right: 6px;
    }
  }
}
