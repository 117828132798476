mat-expansion-panel.mat-expansion-panel {

  // Setting default font ligatures for expansion panel
  // v16 has reduced font size for expansion panel
  mat-expansion-panel-header.mat-expansion-panel-header  {
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;

    &.no-height-header {
      height: unset;
    }
  }

  .mat-expansion-panel-content {
    font-size: 1rem;
    font-weight: 400;
  }

  &.no-shadow {
    box-shadow: unset;
  }

  &.art-portal-settings-panel {
    border: 1px solid $gf-hint-text;

    &:hover:not(.mat-expanded) {
      outline: 1px solid $gf-hint-text;
    }

    .mat-expansion-panel-header {
      padding: 0 1rem;

      &:hover:not(.mat-expanded) {
        background-color: transparent;
      }

      &:hover.mat-expanded {
        background-color: map-get($gf-grey, 50);
      }
    }

    .mat-expansion-panel-header.mat-expanded {
      height: 48px;
    }

    .mat-expansion-panel-body {
      padding: 0 1rem 1rem;
    }
  }

  &.time-line-title {

    &.primary {
      --mat-expansion-header-text-color: #{$gf-primary-text};
      --mat-expansion-header-description-color: #{$gf-hint-text};

      .mat-content {
        flex-direction: column;
      }

      .mat-expansion-panel-header {
        padding: 1rem 1.5rem 1.5rem !important;

        &:hover {
          background: unset;
        }
      }
    }

    &.secondary {
      --mat-expansion-header-text-color: #{$gf-hint-text};
      margin: 1rem 0 0 !important;

      .mat-expansion-panel-header {
        padding: 0 .5rem !important;
        width: fit-content;

        &:hover {
          background: unset;
        }
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0 !important;
        }
      }

      .mat-content {
        flex-direction: column;
      }

      &.monthly-events {
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            margin-top: 1rem !important;
          }
        }
      }
    }
  }

  &.expansion-panel {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0 2rem 1.5rem !important;
      }
    }
  }

  &.disable-expansion-panel-body {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0 !important;
      }
    }
  }

  &.version-control-expansion-panel {
    max-height: 372px;
    border-radius: 0 0 2px 2px !important;
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.1);

    &.align-with-item-card {
      margin-left: 2.5rem;

      @include breakpoint(medium down) {
        margin-left: unset;
      }
    }

    .mat-expansion-panel-content {
      height: inherit;
      max-height: inherit;

      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}
