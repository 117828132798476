@import 'imports';

body.default {

  .mat-mdc-chip-listbox {
    &.mdc-evolution-chip-set {
      box-sizing: border-box;

      .mdc-evolution-chip-set__chips {
        margin-left: 0;
      }
    }

    .mat-mdc-standard-chip {
      margin: .25rem .125rem;
      padding: .5rem 1rem;

      &.not-selectable-chip {
        --mdc-chip-label-text-color: $gf-secondary-text;
        height: 1.5rem;
        margin: 0 0 0 2.5rem;
        padding: 2px 22px;
        @include t(13px, 500, s);

        @include breakpoint(small down) {
          margin: 1rem 0 0 1rem;
        }
      }

      &:hover .mat-mdc-chip-focus-overlay {
        opacity: .12;
      }

      &.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic,
      &.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic {
        padding-left: 0;
        padding-right: 0;
      }

      &.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary,
      &.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
        padding-right: 0;
      }

      .mat-mdc-chip-remove {
        --mdc-chip-with-trailing-icon-trailing-icon-color: #000000de;
        opacity: 0.4;

        &:hover {
          opacity: 0.54;
        }
      }

      &.mat-accent {
        background-color: map-get($gf-grey, 100);

        &.mat-chip-selected {
          color: $oc-white;

          &.mat-accent {
            background-color: $oc-blue-10;
          }
        }

        &:focus {
          color: $oc-white;
        }
      }

      &.is-email {
        color: $oc-black;
        background-color: $oc-white;
        border: solid 1px rgba(0, 0, 0, 0.15);
        border-radius: 1.25rem;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
        font-weight: 500;
      }

      &.email-warn {
        color: $oc-black;
        background-color: map-get($gf-orange, 30);
        border: solid 1px map-get($gf-orange, 200);
        border-radius: 1.25rem;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
        font-weight: 500;

        .mat-icon {
          color: map-get($gf-orange, 200);
        }

        &:hover {
          background-color: map-get($gf-orange, 50) !important;
        }

        &::after {
          background-color: transparent;
        }
      }
    }
  }
}
