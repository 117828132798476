$default-slider-height: 8px;

body.default {

  .mat-mdc-slider {
    --mdc-slider-active-track-height: #{$default-slider-height};
    --mdc-slider-inactive-track-height: #{$default-slider-height};
    --mdc-slider-inactive-track-shape: 4px;
    --mdc-slider-label-container-color: transparent;
    --mdc-slider-handle-width: 28px;
    --mdc-slider-handle-height: 28px;
    --mdc-slider-disabled-active-track-color: #{map-get($gf-grey, 200)};
    --mdc-slider-disabled-inactive-track-color: #{$gf-divider};
    height: var(--mdc-slider-active-track-height);
    margin-left: 0;
    margin-right: 0;

    &.mat-primary {
      --mdc-slider-inactive-track-color: #{$gf-divider};
      --mat-mdc-slider-ripple-color: transparent;
      --mat-mdc-slider-hover-ripple-color: transparent;
      --mat-mdc-slider-focus-ripple-color: transparent;
    }

    // When slider is disabled
    &.mdc-slider--disabled {
      opacity: 1;

      .mdc-slider__thumb .mdc-slider__thumb-knob {
        border: 6px solid var(--mdc-slider-disabled-active-track-color);
      }

      .mdc-slider__value-indicator {
        transform: scale(0);
      }
    }

    .mdc-slider__input {
      height: 100%;
      top: 0;
    }

    .mdc-slider__thumb {
      height: 100%;

      .mdc-slider__thumb-knob {
        background-color: $oc-white;
        border: 6px solid map-get($gf-orange, 300);
        box-shadow: unset;
        transform: translate(-50%, -50%) scale(0.7);
      }
    }

    .mdc-slider__track--inactive {
      opacity: 1;
    }
  }

  // Custom slider display label
  .mdc-slider {
    --mdc-slider-label-label-text-size: 12px;
    --mdc-slider-label-label-text-color: #{$gf-secondary-text};
    --mat-mdc-slider-value-indicator-opacity: 1;

    .mdc-slider__value-indicator-container {
      bottom: 0;
      top: 10px;
    }

    .mdc-slider__value-indicator {
      transform: scale(1);
    }
  }
}
