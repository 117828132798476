@import 'oc-color';

// Previously these variables from angular material were exposed by accident.
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

@function map-oc-to-mat($oc-color) {
  @return (
    50: map-get($oc-color, '0'),
    100: map-get($oc-color, '1'),
    200: map-get($oc-color, '2'),
    300: map-get($oc-color, '3'),
    400: map-get($oc-color, '4'),
    500: map-get($oc-color, '5'),
    600: map-get($oc-color, '6'),
    700: map-get($oc-color, '7'),
    800: map-get($oc-color, '8'),
    900: map-get($oc-color, '9'),
    1000: map-get($oc-color, '10'),
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      1000: $light-primary-text,
    )
  );
}
