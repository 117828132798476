mat-form-field.mat-mdc-form-field {

  // changes related to form-field active state
  --mdc-filled-text-field-active-indicator-color: #0000006b;

  // changes related to form-field hover state
  --mdc-filled-text-field-hover-active-indicator-color: #0000006b;

  // changes related to form-field focus state
  --mdc-filled-text-field-focus-active-indicator-color: #0000006b;
  --mdc-filled-text-field-focus-label-text-color: #{$dark-secondary-text};
  --mdc-filled-text-field-error-focus-label-text-color: #{map-get($gf-grey, 300)};

  // changes related to form-field background
  --mdc-filled-text-field-container-color: #{map-get( $gf-black, 100)};
  --mdc-filled-text-field-disabled-container-color: #{map-get( $gf-black, 100)};

  // changes related to form-field label
  --mdc-filled-text-field-label-text-color: #{$dark-secondary-text};
  --mdc-filled-text-field-error-label-text-color: #{map-get($gf-grey, 300)};
  --mdc-filled-text-field-disabled-label-text-color: #{$dark-secondary-text};

  // changes related to form-field placeholder
  --mdc-filled-text-field-input-text-placeholder-color: #{map-get($gf-grey, 200)};

  // changes related to form-field subscript height issue
  --mat-form-field-subscript-text-line-height: 16px;

  // changes related to form-field border state
  .mdc-text-field--filled {
    --mdc-filled-text-field-active-indicator-height: 0;
    border-bottom-right-radius: var(--mdc-filled-text-field-container-shape);
    border-bottom-left-radius: var(--mdc-filled-text-field-container-shape);

    &.mdc-text-field--focused,
    &.mdc-text-field--invalid,
    &:hover:not(.mdc-text-field--disabled) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.mdc-text-field--invalid,
    &:hover:not(.mdc-text-field--disabled) {
      --mdc-filled-text-field-active-indicator-height: 2px;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    background: unset;
  }

  .mat-mdc-form-field-hint {
    font-size: 12px;
    color: #4a505699;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 12px;
  }

  // changes related to form-field subscript height issue
  .mat-mdc-form-field-bottom-align::before  {
    height: unset;
  }

  &.mat-form-field-appearance-fill {

    .mat-mdc-text-field-wrapper {
      &.mdc-text-field--invalid {
        input {
          color: map-get($gf-red, 500);
        }
      }
    }

    .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
      height: 1.5rem;
      width: 1.5rem;

      mat-icon {
        padding: 0;
      }
    }

    &.mat-focused {
      .mdc-line-ripple {
        background-color: map-get($gf-black, 600) !important;
      }
    }

    &.no-borders {
      .mdc-line-ripple {
        display: none;
      }
    }
  }

  &.mat-form-field-invalid {

    .mat-mdc-form-field-subscript-wrapper {
      mat-error {
        @include t(12px, 400, p);
        color: map-get($gf-red, 500);
        -webkit-font-smoothing: auto;
      }
    }
  }

  &.border-input.mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
      border: 1px solid map-get($gf-grey, 100);
    }
  }

  &.border-input.mat-form-field-appearance-outline {

    .mat-mdc-text-field-wrapper {
      padding: 0 12px;

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-infix {
          padding: .5rem 0;
          min-height: 46px;
          display: flex;
          align-items: center;

          .mat-mdc-select-placeholder, .mat-mdc-select-value-text {
            color: $gf-primary-text;
            -webkit-font-smoothing: auto;
          }
        }
      }

      &.mdc-text-field--invalid {
        .mdc-notched-outline {
          .mdc-notched-outline__leading {
            border-width: 2px;
            border-color: map-get($gf-red, 500);
          }
          .mdc-notched-outline__trailing {
            border-width: 2px;
            border-color: map-get($gf-red, 500);
          }
        }

        .mat-mdc-input-element {
          color: $gf-rejected-red;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-error {
        font-size: 12px;
        -webkit-font-smoothing: auto;
      }
    }

    &.supress-border.mat-focused .mat-mdc-notch-piece {
      border-color: map-get($gf-grey, 300);
    }
  }

  &.error-none {
    &.mat-form-field-appearance-fill, &.mat-form-field-appearance-outline {

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      border-top: none;
      color: $gf-primary-text;
    }

    .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
      height: 1.5rem;
      width: 1.5rem;

      mat-icon {
        padding: 0;
      }
    }

    &.no-borders {
      .mdc-notched-outline {
        display: none;
      }
    }
  }

  &.mat-form-field-appearance-fill, &.mat-form-field-appearance-outline {
    .mat-mdc-form-field-icon-suffix {
      .mat-datepicker-toggle button {
        padding: 0;
        line-height: 20px;
        height: 24px;
        width: 24px;

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  &.normal-input {
    &.mat-form-field-appearance-fill {

      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            padding: 12px 24px;
            min-height: 48px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &.custom-bottom-padding {
      .mat-mdc-text-field-wrapper {
        margin-bottom: .5rem;
      }
    }

    .mdc-text-field {
      padding: 0;
    }
  }

  &.base-input {
    &.mat-form-field-appearance-fill {

      .mat-mdc-text-field-wrapper {
        padding: .5rem 1rem;
        background-color: map-get($gf-grey, 50);

        .mat-mdc-form-field-infix {
          padding: 0;

          .mat-mdc-input-element {
            @include t(14px, 400, p);
            -webkit-font-smoothing: auto;
          }
        }

        &:has(input) {
          padding: 0 1rem;

          .mat-mdc-form-field-infix {
            padding: 0;
            min-height: 40px;
            display: flex;
            align-items: center;

            .mat-mdc-input-element {
              @include t(14px, 400, p);
              -webkit-font-smoothing: auto;
            }
          }
        }

        &:has(mat-select) {
          .mat-mdc-form-field-infix {
            min-height: unset;
          }
        }
      }

      .mat-mdc-form-field-focus-overlay {
        display: none;
      }
    }

    &.default-bg {
      .mat-mdc-text-field-wrapper {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }

  &.rounded-fill {
    .mat-mdc-text-field-wrapper {
      align-items: center;
      background-color: map-get($gf-black, 100) !important;
      border-radius: 8px;
      padding: 0 1rem;

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-infix {
          padding: 12px 16px;
          min-height: 28px;
          @include t(16px, 400, p);
          -webkit-font-smoothing: auto;

          .mat-chip {
            @include t(13px, 400, p);
          }

          input::placeholder {
            @include t(16px, 400, h);
          }
        }
      }
    }

    .mdc-line-ripple, .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-focus-overlay {
      display: none;
    }
  }

  // TODO: Pin down design on which input styles they want and restrict them to those ones only
  &.small-rounded-fill {
    .mat-mdc-text-field-wrapper {
      align-items: center;
      background-color: map-get($gf-black, 100);
      border-radius: 9999px !important;
      padding: 0 1rem;

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
          height: 1.5rem;
          width: 1.5rem;
          color: rgba(0, 0, 0, 0.54);
          margin-right: 8px;
          padding: 0;

          mat-icon {
            padding: 0;
          }
        }

        .mat-mdc-form-field-infix {
          padding: 0;
          min-height: 32px;
          display: flex;
          align-items: center;

          input {
            @include t(14px, 400, p);
            -webkit-font-smoothing: auto;

            ::placeholder {
              color: $gf-hint-text;
            }
          }
        }
      }
    }

    .mdc-line-ripple, .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.square-fill {
    .mat-mdc-text-field-wrapper {
      padding: 0;

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-infix {
          padding: 0;

          .mat-mdc-input-element {
            background-color: $oc-white;
            border-radius: 4px;
            color: $gf-primary-text;
            @include t(14px, 400, p);
            -webkit-font-smoothing: auto;

            &::placeholder {
              @include t(14px, 400, h);
            }

            &:not(:read-only):hover, &:not(:read-only):focus {
              background-color: map-get($gf-black, 200);
            }
          }
        }
      }

      &.mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: 0;
        --mdc-outlined-text-field-focus-outline-width: 0;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.stock-art-search {
    .mat-mdc-text-field-wrapper {
      padding: 0 .75rem !important;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding: 0 .5em !important;

          input {
            margin-bottom: .1rem;
            line-height: unset !important;
          }
        }
      }
    }
  }

  &.rounded {
    .mat-mdc-text-field-wrapper {
      padding: 0 1rem;

      .mdc-notched-outline {
        .mdc-notched-outline__leading {
          border-radius: 9999px 0 0 9999px;
          min-width: 40px;
        }
        .mdc-notched-outline__trailing {
          border-radius: 0 9999px 9999px 0;
        }
      }

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-infix {
          min-height: 38px;
          padding: 4px 0;
          display: flex;
          align-items: center;

          input {
            @include t(16px, 400, p);
            -webkit-font-smoothing: auto;

            &::placeholder {
              color: $gf-hint-text;
            }
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
      height: 1.5rem;
      width: 1.5rem;

      mat-icon {
        padding: 0;
      }
    }
  }

  &.rounded.thick {
    .mdc-notched-outline {
      .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
        border-color: map-get($gf-grey, 200) !important;
        border-width: 2px;
      }
    }
  }

  &.icon-input {
    &.mat-form-field-appearance-fill {

      .mat-mdc-form-field-flex {
        display: flex;
        align-items: center;
        color: $gf-primary-text;

        .mat-mdc-form-field-infix {
          padding: 0;
          display: flex;
          align-items: center;
          min-height: 48px;

          input::placeholder {
            color: $gf-hint-text;
            -webkit-font-smoothing: auto;
          }
        }
      }

      .mat-form-field-subscript-wrapper, .mat-mdc-form-field-focus-overlay {
        display: none;
      }
    }

    &.added-color-name-field {
      &.mat-mdc-form-field-has-icon-suffix .mdc-text-field.mat-mdc-text-field-wrapper {
        padding-right: .5rem;
      }
    }
  }

  &.full-width-input {
    width: 100%;

    &.mat-form-field-appearance-fill {
      .mat-mdc-form-field-flex {
        padding: 6px 0;
      }
    }
  }

  &.rounded-full {

    .mat-mdc-text-field-wrapper {
      padding: 0;

      .mat-mdc-form-field-flex {
        align-items: center;
        background-color: map-get($gf-black, 100);
        border-radius: 50px;
        padding: 0 1rem;

        .mat-mdc-form-field-infix {
          min-height: 2.5rem;
          padding: 8px;
          border-top: 0;

          input {
            @include t(14px, 400, p);
            -webkit-font-smoothing: auto;
          }
        }

        .mdc-notched-outline {
          display: none;
        }
      }

      .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
        height: 1.5rem;
        width: 1.5rem;
        color: rgba(0, 0, 0, 0.54);
        padding: 0;

        mat-icon {
          padding: 0;
        }
      }
    }
  }

  &.soft-border-input {

    &.mat-form-field-appearance-outline {
      .mat-mdc-text-field-wrapper {
        margin: 0;
        padding: 0 .5rem;
        height: 2rem;
        width: 5rem;
        border: 1px solid map-get($gf-grey, 300);
        border-radius: 6px;

        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            padding: 0;
            min-height: 32px;
            display: flex;
            align-items: center;

            .mat-mdc-input-element {
              @include t(14px, 400, p);
              text-align: center;
              padding-left: 6px;
              -webkit-font-smoothing: auto;
            }
          }
        }

        &.mdc-text-field--invalid .mat-mdc-input-element {
          color: $gf-rejected-red !important;
        }
      }

      &.mat-focused .mdc-notched-outline {
        display: none;
      }
    }

    .mdc-notched-outline, .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    &.round-border {
      &.mat-form-field-appearance-outline {
        .mat-mdc-text-field-wrapper {
          border-radius: 52px;
        }
      }
    }
  }

  &.date-picker-input {
    &.mat-form-field-appearance-fill {

      .mat-mdc-text-field-wrapper {
        padding: 0;
        background-color: map-get($gf-grey, 50);

        &.mat-input-40 {
          .mat-mdc-form-field-infix {
            height: 2.5rem;
          }
        }

        .mat-mdc-form-field-flex {
          padding: 0 1rem;

          .mat-mdc-form-field-infix {
            padding: 0;
            min-height: 2.5rem;
            display: flex;
            align-items: center;

            .mat-mdc-input-element {
              padding: .5rem 0;
              font-size: 14px;
            }
          }
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      &.default-bg {

        .mat-mdc-form-field-flex {
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }
  }

  &.art-cta-input {

    .mat-mdc-text-field-wrapper {
      background-color: transparent;
      border-radius: 4px;
      padding: 0;

      .mat-mdc-form-field-flex {

        .mdc-notched-outline {
          display: none;
        }

        &:has(input) {
          .mat-mdc-form-field-infix {
            padding: 0;
            min-height: 40px;
            display: flex;
            align-items: center;

            input {
              @include t(20px, 500, p);
              -webkit-font-smoothing: auto;
              border-radius: 4px;
            }
          }
        }

        &:has(textarea) {
          .mat-mdc-form-field-infix {
            padding: 0;
            min-height: 100px;

            textarea {
              @include t(14px, 400, p);
              -webkit-font-smoothing: auto;
              height: 100px;
              border-radius: 4px;

              &::placeholder {
                @include t(14px, 400, h);
                -webkit-font-smoothing: auto;
              }
            }

          }
        }
      }
    }

    &.fill-art-input .mat-mdc-input-element {
      padding: 4px 16px;
      text-align: left;

      &:read-only {
        padding-left: 0;
        padding-right: 0;
      }

      &:not(:read-only):hover {
        background-color: map-get($gf-grey, 50);
      }

      &:not(:read-only):focus {
        background-color: map-get($gf-grey, 100);
      }
    }
  }

  &.approval-detail-input {
    .mat-mdc-text-field-wrapper {
      padding: 0 1rem;
      background-color: transparent;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding: 0;

          textarea {
            @include t(40px, 600, p);
            -webkit-font-smoothing: auto;

            &::placeholder {
              @include t(40px, 600, h);
              color: map-get($gf-grey, 200);
              -webkit-font-smoothing: auto;
            }
          }
        }
      }

      .mat-mdc-form-field-focus-overlay {
        display: none;
      }

      &:hover {
        background-color: rgba(0,0,0,0.05);
      }
    }

    &.approval-description {
      .mat-mdc-text-field-wrapper {

        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            min-height: 48px;

            textarea {
              @include t(18px, 400, p);
              -webkit-font-smoothing: auto;

              &::placeholder {
                @include t(18px, 400, h);
                color: map-get($gf-grey, 200);
                -webkit-font-smoothing: auto;
              }
            }

            @include breakpoint(small down) {
              min-height: unset;

              textarea {
                @include t(14px, 400, p);

                &::placeholder {
                  @include t(14px, 400, h);
                }
              }
            }
          }
        }
      }
    }

    &.approval-item-title {
      .mat-mdc-text-field-wrapper {

        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            min-height: 48px;

            textarea {
              @include t(24px, 500, p);
              -webkit-font-smoothing: auto;

              &::placeholder {
                @include t(24px, 500, h);
                color: map-get($gf-grey, 200);
                -webkit-font-smoothing: auto;
              }
            }
          }
        }
      }

      &-detail {
        .mat-mdc-text-field-wrapper {
          padding: 0;

          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
              min-height: 48px;

              textarea {
                @include t(18px, 500, p);
                -webkit-font-smoothing: auto;

                &::placeholder {
                  @include t(18px, 500, h);
                  color: map-get($gf-grey, 200);
                  -webkit-font-smoothing: auto;
                }
              }
            }
          }
        }
      }
    }

    &.approval-item-desc {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {

            textarea {
              @include t(14px, 400, p);
              -webkit-font-smoothing: auto;

              &::placeholder {
                @include t(14px, 400, h);
                color: map-get($gf-grey, 200);
                -webkit-font-smoothing: auto;
              }
            }
          }
        }
      }

      &-detail {
        .mat-mdc-text-field-wrapper {
          padding: 0;

          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
              min-height: 96px;

              textarea {
                padding: .5rem 1rem;
                @include t(14px, 400, p);
                -webkit-font-smoothing: auto;

                &::placeholder {
                  @include t(14px, 400, h);
                  color: map-get($gf-grey, 200);
                  -webkit-font-smoothing: auto;
                }
              }
            }
          }
        }
      }
    }

    &.approval-title {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {

              @include breakpoint(small down) {
                textarea {
                  @include t(18px, 600, p);

                  &::placeholder {
                    @include t(18px, 600, h);
                  }
                }
              }
            }
          }
      }
    }

    .mat-mdc-form-field-subscript-wrapper, .mdc-line-ripple {
      display: none;
    }
  }

  &.normal-fill.mat-form-field-appearance-fill {

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        min-height: 50px;
        padding-bottom: 0;

        .mat-mdc-floating-label {
          top: 26px;
        }
      }
    }
  }

  &.custom-hint {
    padding-bottom: 2rem;

    .mat-mdc-form-field-hint-wrapper {
      margin-top: .5rem;
      padding: 0;

      .mat-mdc-form-field-hint {
        @include t(12px, 400, s);
        font-style: italic;
        color: map-get($gf-grey, 300);
      }

      .mat-mdc-form-field-hint-spacer {
        display: none;
      }
    }
  }
}

//mat-input focused color in creating new design request
.create-design-request-wrap {
  .mat-form-field-appearance-outline.mat-focused .mdc-notched-outline {

    .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
      border-color: $gf-hint-text;
    }
  }

  .mat-mdc-form-field-infix {
    .mat-mdc-input-element {
      caret-color: $gf-hint-text !important;
    }
  }
}

