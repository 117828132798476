mat-form-field.mat-mdc-form-field-type-mat-select {

  &.mat-form-field-appearance-fill {
    .mdc-floating-label {
      color: $gf-hint-text;
    }
    .mat-mdc-select-arrow {
      color: $gf-hint-text;
    }

    &.mdc-text-field--invalid {
      .mat-mdc-select-arrow {
        color: $gf-rejected-red !important;
      }
    }
  }

  &.mat-form-field-appearance-outline {

    &.mat-form-field-invalid {
      .mat-mdc-select-value .mat-mdc-select-placeholder {
        color: $gf-rejected-red !important;
      }
    }
  }

  .mat-mdc-select-value-text {
    color: $gf-primary-text;
  }

  &.mat-form-field-appearance-fill,  &.mat-form-field-appearance-outline {
    .mat-mdc-select-arrow {
      margin: 0 4px;
      border: none;
      display: inline-block;
      height: 8px;
      position: relative;
      width: 8px;
      transform: rotate(45deg);

      svg {
        display: none;
      }

      &::after {
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-right-style: solid;
        border-right-width: 2px;
        content: '';
        display: inline-block;
        height: 8px;
        left: 0px;
        position: absolute;
        top: 2px;
        width: 8px;
      }
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(-16%);
    }
  }
}

.mat-mdc-select-panel {
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.2) !important;
  background-color: $oc-white !important;
  border-radius: 8px !important;

  .mat-pseudo-checkbox {
    display: none;
  }

  &.filter-select-panel {
    border-radius: 8px;
    padding: .5rem;

    .mat-pseudo-checkbox {
      display: unset;
      height: 1rem;
      width: 1rem;
    }

    mat-option.mat-mdc-option {
      @include t(14px, 500, p);
      height: 2.5rem;
      line-height: 2.5rem;

      &:hover {
        background: map-get($gf-grey, 100);
      }

      &.mat-active {
        background: none;
      }

      &.mat-mdc-selected:not(.mat-disabled) {
        color: $gf-primary-text;
      }
    }
  }

  &.select-panel-under-trigger {
    min-width: calc(100% + 24px);
  }

  &.default-select {

    mat-option.mat-mdc-option {
      height: 3rem;
    }
  }

  &.default-category-select {
    max-height: 50vh;
  }

  .teammate-option {
    .mat-pseudo-checkbox {
      display: unset;
      height: 1rem;
      width: 1rem;

      &.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
        background: $oc-blue-13 !important;
        border: none !important;
      }
    }
  }

  &.panel-with-custom-height {
    max-height: 304px;
  }
}


// For when you want a multi- MatSelect's trigger to look the same whether it has values selected or not
.multi-select-label-trigger {
  font-size: 14px;
  font-weight: 500;
}

// Art Description -> Art details field
.only-view {
  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      display: none !important;
    }
  }

  .mdc-line-ripple {
    display: none;
  }
}

// Signup -> Select plan field
.select-plan-panel {
  max-height: 100% !important;
  .mat-mdc-option {
    height: auto !important;

    .mat-pseudo-checkbox {
      display: none;
    }

    &.divider {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
