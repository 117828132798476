
// TODO: Move somewhere better
$i: 0;
@each $colorMap, $colorName in $oc-color-list {
  $from: map-get($colorMap, '3');
  $to: map-get($colorMap, '6');
  .icon-bg-#{$i - 1} {
    background: radial-gradient(circle at 0 0, #{$from} 10%, #{$to} 90%);
  }
  $i: $i + 1;
}
