@use '@angular/material' as mat;
@include mat.radio-density(-1);

// COLORS
$gf-blue: map-oc-to-mat($oc-blue-list);
$gf-yellow: map-oc-to-mat($oc-yellow-list);

$gf-red: (
  100: #fae9e3,
  300: #f03e3e,
  400: #eb6538,
  450: #df5b2f,
  500: #db4716,
  contrast: (
    400: $oc-white,
    500: $oc-white
  )
);
$gf-red: map_merge(map-oc-to-mat($oc-red-list), $gf-red);

$gf-green: (
  100: #a0e857,
  200: #a7e921,
  300: #9adb16,
  400: #8bd94c
);
$gf-green: map_merge(map-oc-to-mat($oc-green-list), $gf-green);

$gf-grey: (
  50: #f5f6f7,
  75: #e0effd,
  100: #eaecef,
  200: #b7bdc3,
  300: #90969d,
  400: #4a5056,
  500: #21282e
);
$gf-grey: map_merge(map-oc-to-mat($oc-gray-list), $gf-grey);

$gf-black: (
  100: #f5f5f5,
  200: #f8f9fa,
  250: rgba(0,0,0,0.08),
  300: rgba(0,0,0,0.12),
  400: rgba(0,0,0,0.15),
  500: rgba(0,0,0,0.38),
  600: rgba(0,0,0,0.42),
  700: rgba(0,0,0,0.54),
  900: rgba(0,0,0,0.87),

  contrast: (
    600: $oc-white,
    700: $oc-white,
    800: $oc-white,
    900: $oc-white,
  )
);

$gf-orange: (
  30: #fff7eb,
  50: #ffe2b8,
  100: #ffcd3e,
  150: #ffae1a,
  200: #ffa000,
  300: #f2a20e,
  500: #ff9e00,
  700: #e27d26,
  800: #e59000,
  contrast: (
    700: $oc-black,
    800: $oc-white,
    900: $oc-white,
  )
);

$gf-yellow: (
  100: #fff4d9,
  400: #ffcf5c
);


// THEME
$gf-background-color: $oc-white;
$gf-primary-text: map-get($gf-grey, 500);
$gf-progress-color: #4E96FE;
$gf-secondary-text: map-get($gf-grey, 400);
$gf-hint-text: map-get($gf-grey, 300);
$gf-disabled-text: $gf-hint-text;
$gf-link-color: map-get($gf-blue, 1000);
$gf-divider: map-get($gf-grey, 100);
$gf-drop-background: mat.m2-get-color-from-palette($gf-grey, 500, 0.5);
$gf-selected-background: #e3f2fd;
$gf-selected-border: map-get($gf-blue, 500);
$gf-verified-green: map-get($gf-green, 400);
$gf-approved-green: map-get($gf-green, 300);
$gf-rejected-red: map-get($gf-red, 500);

$gf-override-background: (
  background: $gf-background-color,
  tooltip: map_get($gf-grey, 900)
);

$gf-override-foreground: (
  divider: $gf-divider,
  secondary-text: $gf-secondary-text,
  hint-text: $gf-hint-text
);

// Typography
$gf-typography: mat.m2-define-typography-config(
  $font-family: '"Inter", sans-serif',
  $subtitle-2: mat.m2-define-typography-level(18px, 28px, 400),
  $subtitle-1: mat.m2-define-typography-level(16px, 24px, 400),
  $body-2: mat.m2-define-typography-level(13px, 20px, 400),
  $body-1: mat.m2-define-typography-level(16px, 20px, 400),
  $caption: mat.m2-define-typography-level(14px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500),
);

// DEFAULT THEME
$gf-primary: mat.m2-define-palette($gf-orange);
$gf-accent: mat.m2-define-palette(mat.$m2-blue-palette);
$gf-danger-color: map-get($gf-red, 300);
$gf-warn: mat.m2-define-palette($gf-red);
$gf-theme: mat.m2-define-light-theme((
  color: (
    primary: $gf-primary,
    accent: $gf-accent,
    warn: $gf-warn
  ),
  typography: $gf-typography,
  density: 0,
 ));

// Background Overrides (see $gf-background)
$background: map-get($gf-theme, background);
$gf-background: map_merge($background, $gf-override-background);
$gf-theme: map_merge($gf-theme, (background: $gf-background));

// Foreground Overrides (see $gf-foreground)
$foreground: map-get($gf-theme, foreground);
$gf-foreground: map_merge($foreground, $gf-override-foreground);
$gf-theme: map_merge($gf-theme, (foreground: $gf-foreground));

$gf-typography-colors: (
  p: $gf-primary-text,
  s: $gf-secondary-text,
  h: $gf-disabled-text,
  l: $gf-link-color
);

$gf-grey-background: #616161;
