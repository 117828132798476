body.default {

  .mat-mdc-tab-nav-bar {
    --mat-tab-header-inactive-label-text-color: #{$gf-hint-text};
    --mat-tab-header-inactive-hover-label-text-color: #{$gf-hint-text};
    --mat-tab-header-inactive-focus-label-text-color: #{map-get($gf-orange, 200)};

    &.border-tab-nav .mat-mdc-tab-link-container {
      border-bottom: 1px solid $gf-divider;
    }

    &.mat-mdc-tab-header {
      --mdc-tab-indicator-active-indicator-height: 4px;
      //NOTE: variable refer to tab active indicator border radius
      --mdc-tab-indicator-active-indicator-shape: 1rem;
    }

    .mat-mdc-tab-link-container {

      .mat-mdc-tab-link {
        min-width: unset;
        padding: 0.625rem 1rem;
        opacity: 1;
        flex-grow: 0;

        .mdc-tab__content {
          height: 100%;
        }
      }
    }
  }
}
