@import 'imports';

body.default .mat-divider {
  border-top-color: map-get($gf-grey, 100);

  &.grey-medium-border {
    border-top-color: map-get($gf-grey, 200);
  }

  &.small-divider {
    max-width: 100px;
    margin: 0 auto !important;
  }

  &.bolder {
    --mat-divider-width: 2px;
  }
}
