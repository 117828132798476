/* Material Loader style overwrite here */
body.default {
  .mat-mdc-progress-spinner {

    &.mat-primary {
      --mdc-circular-progress-active-indicator-color: #{map-get($gf-orange, 300)};
    }

    &.mat-accent {
      --mdc-circular-progress-active-indicator-color: green;
    }

    &.mat-warn {
      --mdc-circular-progress-active-indicator-color: red;
    }

    &.mat-progress {
      --mdc-circular-progress-active-indicator-color: #{$gf-progress-color};
    }
  }
}
