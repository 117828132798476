@mixin inheritTextStyle() {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

@mixin checkBoxPosition() {
  top: -11.5px;
  left: -11.5px;
}

body.default {

  .mat-mdc-checkbox {
    --mdc-checkbox-selected-focus-icon-color: #{$oc-blue-12};
    --mdc-checkbox-selected-hover-icon-color: #{$oc-blue-12};
    --mdc-checkbox-selected-icon-color: #{$oc-blue-12};
    --mdc-checkbox-selected-pressed-icon-color: #{$oc-blue-12};
    --mdc-checkbox-selected-focus-state-layer-color: #{$oc-blue-12};
    --mdc-checkbox-selected-hover-state-layer-color: #{$oc-blue-12};
    --mdc-checkbox-selected-pressed-state-layer-color: #{$oc-blue-12};

    .mdc-form-field {
      @include inheritTextStyle();

      .mdc-checkbox {
        padding: 0;
        margin: auto;
        margin-right: .5rem;
        width: 1rem;
        height: 1rem;
        flex: 0 0 1rem;

        .mdc-checkbox__native-control {
          top: -0.75rem;
          left: -0.75rem;

          &:focus~.mdc-checkbox__ripple {
            opacity: 0;
          }
        }
      }
    }

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
      background-color: $oc-white;
    }

    label {
      @include inheritTextStyle();
      padding-left: 0;
      white-space: normal;
    }

    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
      height: 2.5rem;
      width: 2.5rem;
      top: -0.75rem;
      left: -0.75rem;
    }

    .mdc-checkbox__background {
      width: 1rem;
      height: 1rem;
      top: 0px;
      left: 0px;
      border-width: 1px;
      background-color: $oc-white;
      border-color: map-get($gf-black, 700);
    }

    &.select-all {

      .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
      .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
        background-color: $oc-white;
        border-color: $oc-white;
        --mdc-checkbox-selected-checkmark-color: #{$oc-blue-12};
      }

      .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
        width: 100%;
      }
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
      border-width: 2px;

      .mdc-checkbox__mixedmark {
        width: calc(100% - 2px);
      }
    }

    &.tag-item {
      @include t(16px, 500, p);
      cursor: pointer;
      display: flex;
      flex-flow: row wrap;
    }

    &.dont-show-me {
      @include t(16px, 400, p);
      cursor: pointer;
      display: flex;
      flex-flow: row wrap;

      .mdc-checkbox {
        margin-right: 1rem;

        .mat-mdc-checkbox-touch-target {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    &.tag-item,
    &.archive-checkbox {

      .mdc-checkbox {
        width: 1.125rem;
        height: 1.125rem;
        flex: 0 0 1.125rem;

        .mdc-checkbox__native-control {
          @include checkBoxPosition();
        }
      }

      .mdc-form-field {
        max-width: 100%;

        label {
          line-height: 1.5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple {
        @include checkBoxPosition();
      }

      .mdc-checkbox__background {
        width: 1.125rem;
        height: 1.125rem;
      }
    }

    @include breakpoint(small down) {
      &.style-filter {

        &.mat-mdc-checkbox-checked {
          color: $oc-blue-13;
        }

        .mdc-checkbox {
          width: 1.125rem;
          height: 1.125rem;
          flex: 0 0 1.125rem;
          margin-right: .75rem;

          .mdc-checkbox__native-control {
            @include checkBoxPosition();
          }
        }

        .mdc-form-field {
          max-width: 100%;

          label {
            line-height: 1.5rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
          }
        }

        .mdc-checkbox__ripple,
        .mat-mdc-checkbox-ripple {
          @include checkBoxPosition();
        }

        .mdc-checkbox__background {
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }
  }
}

// Category filter label
.all-categories-wrapper, .stock-art-category-item {
  label {
    color: map-get($gf-grey, 500) !important;
  }
}
