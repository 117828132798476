@import '../mixins';

body.default {

  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: .75rem;

    .mat-mdc-dialog-surface {
      max-width: calc(100vw - 1rem);
      max-height: calc(100vh - 1rem);
      overflow: hidden;
      padding: 0;
      box-shadow: none;
      position: unset;
    }

    .mdc-dialog__title {
      margin: 0;
      padding: 1.5rem 2.5rem;
      display: flex;
      align-items: center;
      @include t(20px, 500, p);
      color: $oc-black;

      &::before {
        display: none;
      }

      &.custom-dialog-title {
        padding: 1.5rem 2.5rem 1rem 2.5rem;
      }

      @include breakpoint(medium down) {
        padding: 1.5rem 2.5rem;
      }
    }

    .mdc-dialog__content {
      color: $oc-black;
      font-size: 1rem;
      line-height: 1.5;
      margin: 0;
      min-height: 60px;
      overflow-y: auto;
      padding: 0 2.5rem 2.5rem;
    }

    .mdc-dialog__actions {
      margin-bottom: 0;
      padding: 0 2.5rem 2.5rem;

      >button:last-child {
        margin-right: 0;
      }
    }
  }

  #deleteApprovalActionsDialog {
    .mat-mdc-dialog-content {
      margin: 0.5rem;

      @include breakpoint (tiny only) {
        padding: 0 1.5rem 2.5rem;
        margin: 0;
      }
    }
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    box-sizing: content-box;
    border-top: none;
  }

  .full-screen-modal {
    max-width: unset !important;
    width: 100%;
    height: 100%;

    .mat-mdc-dialog-container {
      max-width: 100vw;
      max-height: 100vh;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      border-radius: unset;

      .mat-mdc-dialog-surface {
        max-width: inherit;
        max-height: inherit;
        border-radius: 0;
      }

      .mdc-dialog__content {
        max-height: unset;
      }
    }
  }

  @include breakpoint(small down) {
    mat-dialog-actions {
      padding: 0.5rem 1.5rem 1.5rem !important;

      button {
        width: 100%;

        &:not(:only-child):not(:has(> :nth-child(2))):nth-child(1) {
          order: 2;
          margin-top: 0.5rem;
          margin-right: 0 !important;
        }

        &:nth-child(2){
          margin-left: 0 !important;
        }
      }

      &.border {
        border-top: 1px solid $gf-divider;
        padding: 1rem 1.5rem 1rem !important;
      }
    }

    .mobile-screen-modal {
      max-width: 100%;
      max-height: 100%;

      .mat-mdc-dialog-container {
        mat-dialog-title,
        .mdc-dialog__title {
          padding: 1.5rem;
        }

        mat-dialog-content {
          padding: 0 1.5rem 1rem;
        }
      }

      &-medium-y {
        max-width: 100% !important;
        max-height: 100% !important;

        .mat-mdc-dialog-container {
          mat-dialog-title,
          .mdc-dialog__title {
            padding: 1rem 1.5rem;
          }

          mat-dialog-content {
            padding: 0 1.5rem 1rem;
          }
        }
      }
    }

    #addCollaboratorToApprovalDialog {
      .mdc-dialog__content {
        padding: 0 1.5rem 2.5rem;
      }
    }
  }

  @include breakpoint(medium down) {
    // this will fix the modal view on mobile landscape.
    // for now added here, if it impacts need to create a separate class.
    .cdk-overlay-pane {

      &.mobile-screen-modal, &.mobile-screen-modal-medium-y {
        max-width: 100% !important;
      }
    }
  }

  @include breakpoint(large only) {
    // this will fix the modal view for large only
    .cdk-overlay-pane {
      &.selected-stock-art-modal {
        max-width: 90vw !important;
      }
    }
  }


  .cdk-overlay-backdrop {
    background-color: rgba(33, 40, 46, 0.85);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  // NOTE: Above we have overwritted cdk-overlay-backdrop's background color.
  // Because overwriting cdk-overlay-backdrop it affects the mat-date-picker popup.
  // We don't need cdk-overlay-backdrop background-color for mat date picker popup it should be transparent.
  // If cdk overlay container has mat date picker,then we are unsetting the background color.
  .cdk-overlay-container:has(.cdk-overlay-connected-position-bounding-box > .mat-datepicker-popup) {
    .cdk-overlay-backdrop {
      background-color: unset !important;
      backdrop-filter: none !important;
      -webkit-backdrop-filter: none !important;
    }
  }

  // We have supressed the latest transition method. beacuse we gonna follow the old transition.
  // GF-2618 - This will help us to get rid of the backdrop flicker issue.
  // In Material v14 - we have new property *visibility* that causing the issue.
  // I have tried to play with it, but nothing help us out.
  // latest => transition: opacity 1ms visibility 1ms linear
  // old => transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1)
  .cdk-overlay-transparent-backdrop {
    transition: none;
  }

  .hide-dialog {
    opacity: 0;
  }

  .change-plan-panel {
    @include breakpoint(large down) {
      max-width: 95vw !important;
    }
    max-width: 85vw !important;
  }
}

.welcome-modal-panelclass .mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  }
}
