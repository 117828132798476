body.default {

  .mat-mdc-snack-bar-container {
    margin: 0;

    .mdc-snackbar__surface {
      display: block;
      width: 360px;
      padding: 0;
      margin: 1rem;
      border-radius: 8px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
      background-color: $oc-white;

      @include breakpoint(small down) {
        min-width: unset;
      }

      .mdc-snackbar__label {
        width: unset;
        padding: 0;
      }

      .link {
        @include t(14px, 400, s);
        cursor: pointer;
        color: $gf-link-color;
      }
    }
  }
}
