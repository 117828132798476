@use 'sass:math';

@import 'foundation/util/math';
@import 'foundation/util/unit';
@import 'sass-math-pow/sass/math-pow';

@mixin font-style($font, $font-size, $font-weight, $line-height: $font-size * 1.4, $color: $dark-primary-text) {
  $a: -0.0223;
  $b: 0.185;
  $c: -0.1745;
  $e: 2.718281828;
  $letter-spacing: $a + $b * poly-pow($e, ($c * strip-unit($font-size)));
  color: $color;
  font-family: $font;
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: #{$letter-spacing}px;
  line-height: $line-height;
}

@mixin t($fontSize, $fontWeight, $colorKey: p) {
  @include font-style('Inter', $fontSize, $fontWeight, $fontSize * 1.6, map-get($gf-typography-colors, $colorKey));
}


@mixin card() {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@mixin aspect-ratio($x, $y) {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: (math.div($y, $x) * 100%);

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}
