@import 'imports';

body.default{

  .mat-mdc-tooltip {

    .mdc-tooltip__surface{
      border-radius: 4px;
      background-color: $gf-grey-background;
      font-weight: normal;
      padding: .5rem 1rem;
      max-width: 280px;

      &.link-copied {
        background: $gf-secondary-text;
        opacity: 0.9;
        margin-bottom: 6px;
      }

      &.remove-item {
        margin-bottom: 8px;
      }
    }

    &.capitalize-first-letter {

      .mdc-tooltip__surface {
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .mat-mdc-tooltip-handset {
    &.mat-mdc-tooltip-below {
      margin-top: 4px;
    }

    &.mat-mdc-tooltip-above {
      margin-bottom: 4px;
    }
  }
}

.tooltip-position-center {
  position: relative;
  top: 114px;
}
