.mat-mdc-option.mat-active {
  background: map-get($foundation-palette, secondary) !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background: map-get($gf-grey, 50) !important;
}

.mat-mdc-option {
  color: $gf-primary-text !important;

  .mdc-list-item__primary-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  &.medium-bold-option {
    line-height: 40px !important;
    height: 40px !important;
    @include t(14px, 600, s);
  }
}

.autocomplete-option.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: map-get($gf-orange, 200) !important;
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    &.medium-bold-option {
      line-height: 40px;
      height: 40px;
      @include t(14px, 600, s);
    }

    .mat-pseudo-checkbox-full {
      margin-right: .75rem;
    }
  }

  &.select-plan-panel {

    .mat-mdc-option {
      .mdc-list-item__primary-text {
        overflow: visible;
      }
    }
  }
}
