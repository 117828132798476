mat-form-field.mat-mdc-form-field-type-mat-input .textarea {
  .mat-form-field-infix {
    padding: 0;

    textarea {
      box-shadow: none;
      padding: 0.5rem 1rem !important;
      min-height: 100px !important;
      max-height: 300px !important;
      overflow: auto !important;
    }
  }
}
