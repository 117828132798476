.t-40-700-h {
  @include t(40px, 700, h);
}
.t-40-600-p {
  @include t(40px, 600, p);
}
.t-40-600-h {
  @include t(40px, 600, h);
}
.t-40-500-p {
  @include t(40px, 500, h);
}
.t-40-500-h {
  @include t(40px, 500, h);
}
.t-28-700-p {
  @include t(28px, 700, p);
}
.t-28-600-p {
  @include t(28px, 600, p);
}
.t-32-600-p {
  @include t(32px, 600, p);
}
.t-32-700-p {
  @include t(32px, 700, p);
}
.t-25-600-p {
  @include t(25px, 600, p);
}
.t-24-600-p {
  @include t(24px, 600, p);
}
.t-24-500-p {
  @include t(24px, 500, p);
}
.t-24-400-p {
  @include t(24px, 400, p);
}
.t-20-600-p {
  @include t(20px, 600, p);
}
.t-20-500-p {
  @include t(20px, 500, p);
}
.t-20-600-p {
  @include t(20px, 600, p);
}
.t-20-500-p {
  @include t(20px, 500, p);
}
.t-20-500-h {
  @include t(20px, 500, h);
}
.t-20-400-h {
  @include t(20px, 400, h);
}
.t-20-400-p {
  @include t(20px, 400, p);
}
.t-20-400-s {
  @include t(20px, 400, s);
}
.t-18-600-p {
  @include t(18px, 600, p);
}
.t-18-500-p {
  @include t(18px, 500, p);
}
.t-18-400-p {
  @include t(18px, 400, p);
}
.t-18-400-s {
  @include t(18px, 400, s);
}
.t-16-600-p {
  @include t(16px, 600, p);
}
.t-16-600-h {
  @include t(16px, 600, h)
}
.t-16-600-s {
  @include t(16px, 600, s)
}
.t-16-500-s {
  @include t(16px, 500, s);
}
.t-16-500-p {
  @include t(16px, 500, p);
}
.t-16-500-h {
  @include t(16px, 500, h);
}
.t-16-400-p {
  @include t(16px, 400, p);
}
.t-16-400-s {
  @include t(16px, 400, s);
}
.t-15-600-h {
  @include t(15px, 600, h);
}
.t-15-600-p {
  @include t(15px, 600, p);
}
.t-15-600-s {
  @include t(15px, 600, s);
}

.t-16-700-p {
  @include t(16px, 700, p);
}

.t-14-700-h {
  @include t(14px, 700, h);
}
.t-14-700-s {
  @include t(14px, 700, s);
}
.t-14-700-p {
  @include t(14px, 700, p);
}
.t-14-600-p {
  @include t(14px, 600, p);
}
.t-14-600-s {
  @include t(14px, 600, s);
}
.t-14-600-h {
  @include t(14px, 600, h);
}
.t-14-500-p {
  @include t(14px, 500, p);
}
.t-14-500-s {
  @include t(14px, 500, s);
}
.t-14-500-h {
  @include t(14px, 500, h);
}
.t-14-300-h {
  @include t(14px, 300, h);
}
.t-14-400-p {
  @include t(14px, 400, p);
}
.t-14-400-s {
  @include t(14px, 400, s);
}
.t-14-400-h {
  @include t(14px, 400, h);
}
.t-13-400-p {
  @include t(13px, 400, p);
}
.t-13-400-s {
  @include t(13px, 400, s);
}
.t-13-400-h {
  @include t(13px, 400, h);
}
.t-13-500-p {
  @include t(13px, 500, p);
}
.t-13-500-h {
  @include t(13px, 500, h);
}
.t-13-600-p {
  @include t(13px, 600, p);
}
.t-13-600-s {
  @include t(13px, 600, s);
}
.t-12-600-s {
  @include t(12px, 600, s);
}
.t-12-600-p {
  @include t(12px, 600, p)
}
.t-12-600-h {
  @include t(12px, 600, h)
}
.t-12-500-p {
  @include t(12px, 500, p);
}
.t-12-500-h {
  @include t(12px, 500, h);
}
.t-12-500-s {
  @include t(12px, 500, s);
}
.t-12-400-s {
  @include t(12px, 400, s);
}
.t-12-400-h {
  @include t(12px, 400, h);
}
.t-12-400-p {
  @include t(12px, 400, p);
}
.t-12-300-p {
  @include t(12px, 300, h);
}
.t-11-500-s {
  @include t(11px, 500, s);
}
.t-10-400-p {
  @include t(10px, 400, p);
}
.t-10-500-p {
  @include t(10px, 500, p);
}


.primary {
  color: $gf-primary-text;
}

.secondary {
  color: $gf-secondary-text !important;
}

.hint {
  color: $gf-hint-text;
}

.dark-text {
  color: #000 !important
}

.dark-primary-text {
  color: $dark-primary-text !important;
}

.dark-secondary-text {
  color: $dark-secondary-text;
}

.danger-color {
  color: $gf-danger-color !important;
}

.text-orange {
  color: map-get($gf-orange, 200) !important;
}

.link-color {
  color: $gf-link-color;
}

.white-color {
  color: $oc-white !important;
}
