@import 'imports';

body.default {

  .mat-mdc-slide-toggle {
    --mdc-switch-unselected-focus-state-layer-color: #fafafa;
    --mdc-switch-unselected-handle-color: #fafafa;
    --mdc-switch-unselected-hover-state-layer-color: #fafafa;
    --mdc-switch-unselected-pressed-state-layer-color: #fafafa;
    --mdc-switch-unselected-hover-handle-color: #fafafa;
    --mdc-switch-unselected-focus-handle-color: #fafafa;
    --mdc-switch-unselected-pressed-handle-color: #fafafa;
    --mdc-switch-unselected-track-color: #00000061;
    --mdc-switch-unselected-hover-track-color: #00000061;
    --mdc-switch-unselected-focus-track-color: #00000061;
    --mdc-switch-unselected-pressed-track-color: #00000061;
    --mdc-switch-selected-icon-color: #2196f3;

    &.mat-accent {
      --mdc-switch-selected-focus-state-layer-color: #2196f3;
      --mdc-switch-selected-handle-color: #2196f3;
      --mdc-switch-selected-hover-state-layer-color: #2196f3;
      --mdc-switch-selected-pressed-state-layer-color: #2196f3;
      --mdc-switch-selected-hover-handle-color: #2196f3;
      --mdc-switch-selected-focus-handle-color: #2196f3;
      --mdc-switch-selected-pressed-handle-color: #2196f3;
    }
  }
}
