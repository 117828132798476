@import 'imports';

$mat-radio-size: 20px;

body.default {
  .mat-mdc-radio-group {
    .mat-mdc-radio-button {

      .mdc-radio {
        --mdc-radio-state-layer-size: #{$mat-radio-size};

        .mat-mdc-radio-touch-target {
          width: $mat-radio-size;
          height: $mat-radio-size;
        }

        .mdc-radio__background {
          .mdc-radio__outer-circle {
            border-width: 1px;
            border-color: rgba(0, 0, 0, 0.54);
          }
        }
      }
        
      .mdc-label {
        padding-left: 0;
      }

      .mat-radio-label-content {
        padding-left: 1rem;
      }

      &.mat-accent {
        --mdc-radio-selected-icon-color: #{$oc-blue-12};
        --mdc-radio-unselected-hover-icon-color: #{$dark-secondary-text};
        --mat-radio-ripple-color: #007aff80;
        --mdc-radio-selected-focus-icon-color: #{$oc-blue-12};
        --mdc-radio-selected-hover-icon-color: #{$oc-blue-12};
        --mat-radio-checked-ripple-color: transparent;
        --mdc-radio-selected-pressed-icon-color: #{$oc-blue-12};
      }
    }
  }

  .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
    border-color: $oc-blue-12 !important;
  }

  .mat-mdc-radio-button.mat-accent .mat-mdc-radio-inner-circle {
    background-color: $oc-blue-12 !important;
  }

  .mat-radio-button.mat-accent .mat-ripple-element {
    background-color: rgba(#007aff, .5) !important;
  }
}
