@import 'imports';

mat-icon.mat-icon {
  overflow: unset !important;
  
  &.flip-x {
    transform: scale(-1, 1);
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
    &:active {
    pointer-events: none;
    }
  }

  &.hint {
    color: $gf-hint-text !important;
  }

  &.dark {
    color: $gf-primary-text;
  }
}

// Create design request date picker
.date-picker-toggle {
  .mat-mdc-icon-button {
    color: $gf-hint-text !important;
    height: 2rem;
    width: 2rem;
  }
}

// used to remove the default appearance of mat-icon in tab & mobile (iOS- Safari).
mat-icon {
  appearance: none !important;
  -webkit-appearance: none !important;
}

.card-wrap, .no-folders {
  mat-icon {
    color: map-get($gf-grey, 200);
  }
}

.downloaded-info {
  mat-icon {
    color: map-get($gf-grey, 300);
  }
}

.add-on-icon-wrap {
  mat-icon {
    height: 28px;
    width: 28px;
    color: $oc-white !important;
    padding: 2px;
  }
}

.graphics-builder-icon {
  mat-icon {
    color: map-get($gf-yellow, 400) !important;
    font-size: 16px;
  }

  &.medium {
    mat-icon {
      font-size: 18px;
    }
  }
}
