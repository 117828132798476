@use '@angular/material' as mat;
@import 'imports';

@include mat.core();

@mixin material-theme($theme, $theme-name) {
  body.#{$theme-name} {
    @include mat.all-component-themes($theme);

    .caption {
      color: mat.m2-get-color-from-palette($gf-foreground, secondary-text);
    }

    .mat-tab-group, .mat-tab-nav-bar {
      border-bottom: none;

      .mat-ink-bar {
        border-radius: 1.5px;
        height: 4px;
      }

      &.white { // WHITE themed nav bars
        border-bottom: none;

        .mat-ink-bar {
          background-color: white;
        }
      }
    }

    // TODO: This is really only for the header, clean-up and organize our SCSS files better

    .mdc-tooltip__surface {
      @include font-style('Inter', 12px, 600, 16px, white);
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 9999px;
      max-width: 250px;
    }

    .mat-mdc-menu-panel.color-picker {
      max-width: 100%;
      min-width: 306px;
    }

    .mat-mdc-menu-panel.fabulous-menu {
      background: none;
      box-shadow: none;

      button {
        margin-left: 8px;
      }
    }

    button.mat-black-blue {
      background-color: $oc-black;
      color: map-get($gf-blue, 500);
    }

    button.mat-black-green {
      background-color: $oc-black;
      color: map-get($gf-green, 400);
    }

    button.mat-black-red {
      background-color: $oc-black;
      color: map-get($gf-red, 500);
    }

    button.mat-black-white {
      background-color: $oc-black;
      color: white;
    }

    .mat-secondary {
      color: $gf-secondary-text;
    }

    .material-icons.mat-14 {
      font-size: 14px;
      height: 14px;
      width: 14px;
    }

    .material-icons.mat-16{
      font-size: 16px;
      height: 16px;
      width: 16px;
    }

    .material-icons.mat-18 {
      font-size: 18px;
      height: 18px;
      width: 18px;
    }

    .material-icons.mat-20 {
      font-size: 20px;
      height: 20px;
      width: 20px;
    }

    .material-icons.mat-28 {
      font-size: 28px;
      height: 28px;
      width: 28px;
    }

    .material-icons.mat-30 {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }

    .material-icons.mat-36 {
      font-size: 36px;
      height: 36px;
      width: 36px;
    }

    .material-icons.mat-40 {
      font-size: 40px;
      height: 40px;
      width: 40px;
    }

    .material-icons.mat-48 {
      font-size: 48px;
      height: 48px;
      width: 48px;
    }

    .material-icons.mat-56 {
      font-size: 56px;
      height: 56px;
      width: 56px;
    }

    .material-icons.mat-64 {
      font-size: 64px;
      height: 64px;
      width: 64px;
    }

    .material-icons.mat-120 {
      font-size: 120px;
      height: 120px;
      width: 120px;
    }

    .material-icons.hint {
      color: map-get($gf-grey, 300);
    }

    .material-icons.success {
      color: map-get($gf-green, 300);
    }
  }
}

@include mat.all-component-typographies($gf-typography);



@include material-theme($gf-theme, default);
