body.default {

  .mat-mdc-menu-panel {
    min-height: 56px;
    box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px;

    --mat-menu-item-label-text-size: 14px;
    --mat-menu-item-icon-color:  #{$dark-secondary-text};

    .mat-mdc-menu-content {
      min-width: 180px;
    }

    .mat-mdc-menu-item {
      height: 2.5rem;
      min-height: unset;
      margin: 0;
      padding: 0 24px 0 16px;
      color: map-get($gf-black, 900);

      .mat-mdc-menu-item-text {
        width: 100%;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
      }

      &[disabled] {
        color: map-get($gf-black, 500);
        opacity: 1;
      }

      .mdc-list-item__primary-text {
        color: inherit;
        font-size: 14px;
        font-weight: inherit;
        line-height: 2.5rem;
        text-transform: none;
      }

      .mat-icon-no-color {
        color:  #{$dark-secondary-text};
      }
    }

    .mat-mdc-menu-item[disabled] > .mat-icon {
       color: #00000061;
    }

    &.filter-menu-wrap {
      min-height: unset;
    }

    &.approval-filter {

      @include breakpoint(small down) {
        width: calc(100vw - 2rem);
        max-width: unset;
      }
    }

    &.filter-menu-panel {
      // Added this because, In mat menu for appearing icon at the end of the item has no longer support from anguler material v16
      .mat-mdc-menu-item {
        padding: 0 1rem;
        flex-direction: row-reverse;
        justify-content: flex-end;

        .mat-mdc-menu-item-text {
          flex: 1;
        }

        mat-icon {
          margin-right: 0;
        }
      }
    }

    @include breakpoint(tiny down) {

      &.bulk-action-menu {
        min-width: none;
        max-width: none;
        width: calc(100vw - 32px);
        }
      }
  }
}

// SideNav Artportal link
.art-portal-menu {
  .mat-mdc-menu-item {
    color: map-get($gf-grey, 500) !important;
  }
}

// Artportal sortby menu
.my-art-sort-menu-panel {
  .mat-mdc-menu-content {
    button {
      &.active {
        background-color: map-get($gf-grey, 100);
      }
    }
  }
}

// Clear filter selection
.clear-selection {
  color: $oc-blue-10 !important;

   &.mat-mdc-menu-item {
    @include t(13px, 500, p);
    &:hover {
      background: transparent !important;
    }
  }
}
