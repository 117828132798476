body.default {

  .mat-mdc-table {
    background: transparent;
    min-width: 0;
    width: 100%;

    // For Mobile - Width set to 93vw, because table exceeds the screen in mobile
    &.my-art-table {
      @include breakpoint(small down) {
        width: 93vw;
      }
    }

    tr {

      .th-bulk-selection .mat-mdc-checkbox {
        visibility: hidden;

        @include breakpoint(large down) {
          visibility: visible;
        }

        .mdc-checkbox__native-control:enabled:checked,
        .mdc-checkbox__native-control:enabled:indeterminate {
          visibility: visible;
        }

        .mdc-form-field .mdc-checkbox {
          margin: 0;
        }
      }

      &:hover .th-bulk-selection .mat-mdc-checkbox {
        visibility: visible;
      }

      &:hover.selectable td .td-bulk-selection .mat-mdc-checkbox {

        .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      &.mat-mdc-row {
        height: 57px;
        vertical-align: middle;

        .td-bulk-selection .mat-mdc-checkbox {
          visibility: hidden;

          @include breakpoint(small down) {
            visibility: visible;
          }

          &.mat-mdc-checkbox-checked {
            visibility: visible;
          }

          .mdc-form-field .mdc-checkbox {
            margin: 0;
          }
        }

        @include breakpoint(large up) {

          &:hover .td-bulk-selection .mat-mdc-checkbox,
          &.selectable .td-bulk-selection .mat-mdc-checkbox {
            visibility: visible;
          }
        }
      }

      &:not(.mdc-data-table__row--selected) {
        &:hover {
          background-color: unset;
        }
      }

      th {
        @include t(13px, 600, s);
        color: $dark-primary-text;
        border-bottom-width: 2px;
        padding: 0.75rem 0.5rem;
        background-color: transparent;
        overflow: unset;
        border-bottom-color: $gf-divider;

        &.mdc-data-table__header-cell:first-of-type {
          padding-left: 1rem;

          @include breakpoint(tiny down) {
            padding-left: 0;
          }
        }

        &:last-of-type {
          padding-right: 0.5rem;
        }

        .mat-sort-header-arrow {
          color: $oc-black;
        }
      }

      td {
        @include t(13px, 400, p);
        color: $gf-primary-text;
        padding: 0.5rem;
        overflow: unset;
        border-bottom-color: $gf-divider;

        &:not(td.img-col).mat-mdc-cell:first-of-type {
          padding-left: 1rem;

          @include breakpoint(tiny down) {
            padding-left: 8px;
          }
        }

        &.mat-mdc-cell:last-of-type {
          padding-right: 0.5rem;
        }
      }
    }
  }
}
